import { isNull, isUndefined } from "lodash";
import axios from "axios";
import Cookies from "js-cookie";
export const isNullOrUndefined = (value) => {
  return isNull(value) || isUndefined(value);
};

export const isNullOrUndefinedOrBlank = (value) => {
  return isNull(value) || isUndefined(value) || value === "";
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 6,
    },
  },
};

export const WithAccess = async (userId, moduleId) => {
  let result = false;
  try {
    await axios
      .get(
        Cookies.get("ApiUrl") + "GetListOfRecords",
        {
          params: {
            sp: "udp_WithAccess",
            parameters: {
              userId: userId,
              moduleId: moduleId,
            },
          },
        },
        { headers: { Authorization: "Bearer " + Cookies.get("TokenId") } }
      )
      .then((res) => {
        if (res.data.length > 0) {
          result = true;
        } else {
          result = false;
        }
      })
      .catch((err) => {});
  } catch (error) {
    result = false;
  }
  return result;
};

export const WithUserAccess = async (username, password, moduleId) => {
  let result = false;
  try {
    await axios
      .post(
        Cookies.get("ApiUrl") + "WithUserAccess",
        {
          username,
          password,
          moduleId,
        },
        { headers: { Authorization: "Bearer " + Cookies.get("TokenId") } }
      )
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        result = false;
      });
  } catch (error) {
    result = false;
  }
  return result;
};

export async function downloadReport(payLoad) {
  const url = Cookies.get("ApiUrl") + "downloadreport";

  try {
    await axios(
      {
        url: url, //your url
        method: "GET",
        responseType: "blob", // important
        params: {
          reportName: payLoad.reportName,
          parameters: payLoad.parameters,
        },
      },
      { headers: { Authorization: "Bearer " + Cookies.get("TokenId") } }
    ).then((response) => {
      let newBlob;

      if (payLoad.isExcel === false) {
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });
      } else {
        newBlob = new Blob([response.data], {
          type: "application/excel",
        });
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);

      const a = document.createElement("a");
      // a.download = "xxx.pdf";

      a.setAttribute("href", data);
      a.setAttribute("target", "_blank");
      // a.setAttribute("download", "xxx.pdf");
      a.click();
    });
  } catch (error) {
    console.log("xxxxxxxxx");
    let errorString = error.response.data;
    errorString = JSON.parse(await error.response.data.text());
    return { Status: 0, Message: errorString.Message };
  }
}

export async function previewReport(payLoad) {
  const url = Cookies.get("ApiUrl") + payLoad.url;

  try {
    const res = await axios(
      {
        url: url, //your url
        method: "GET",
        responseType: "blob", // important
        params: {
          reportName: payLoad.reportName,
          download: payLoad.download,
          downloadFormat: payLoad.downloadFormat,
          parameters: payLoad.parameters,
        },
      },
      { headers: { Authorization: "Bearer " + Cookies.get("TokenId") } }
    ).then((response) => {
      let newBlob;

      if (payLoad.download === false) {
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);

        const a = document.createElement("a");
        // a.download = "xxx.pdf";

        a.setAttribute("href", data);
        a.setAttribute("target", "_blank");
        // a.setAttribute("download", "xxx.pdf");
        a.click();
      } else {
        if (payLoad.downloadFormat === "XLS") {
          newBlob = new Blob([response.data], {
            type: "application/excel",
          });
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(newBlob);
          a.href = url;
          a.download = "download.xls";
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);
        } else {
          newBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(newBlob);
          a.href = url;
          a.download = "download.pdf";
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);
        }
      }
    });
  } catch (error) {
    let errorString = error.response.data;
    errorString = JSON.parse(await error.response.data.text());
    return { Status: 0, Message: errorString.Message };
  }
}

export async function previewReportPost(payLoad) {
  try {
    await axios
      .post(Cookies.get("ApiUrl") + "laboratoryreports", payLoad, {
        headers: { Authorization: "Bearer " + Cookies.get("TokenId") },
      })
      .then((res) => {
        let newBlob;
        if (payLoad.download === false) {
          newBlob = new Blob([res.data], {
            type: "application/pdf",
          });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          const data = window.URL.createObjectURL(newBlob);

          const a = document.createElement("a");
          // a.download = "xxx.pdf";

          a.setAttribute("href", data);
          a.setAttribute("target", "_blank");
          // a.setAttribute("download", "xxx.pdf");
          a.click();
        }
      });
  } catch (error) {
    console.log(error);
  }
}
