import React, { useState } from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
DocumentEditorContainerComponent.Inject(Toolbar);
const DocumentEditor = () => {
  const [docEditor, setDocEditor] = useState(null);
  const [serviceUrl] = useState(
    "https://ej2services.syncfusion.com/production/web-services/"
  );
  const initDocEditor = (editor) => {
    if (editor && !docEditor) {
      setDocEditor(editor);
    }
  };
  function onCreated() {
    alert("Hello");
  }
  return (
    <div>
      <DocumentEditorContainerComponent
        width="100%"
        height="650px"
        enableToolbar={true}
        created={onCreated}
        ref={initDocEditor}
      ></DocumentEditorContainerComponent>
    </div>
  );
};

export default DocumentEditor;
