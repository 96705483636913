/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import Routesx from "./Routes";
import Cookies from "js-cookie";
import { isNullOrUndefinedOrBlank, WithAccess } from "helpers";
import { ToastContainer, toast } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  FileTextOutlined,
  FileDoneOutlined,
  MedicineBoxOutlined,
  AuditOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import { Layout, Menu, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

const { Footer, Sider, Content } = Layout;
const { confirm } = Modal;
const queryClient = new QueryClient();
export const AppContext = createContext();

const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
axios.defaults.baseURL = apiUrl;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + Cookies.get("TokenId");
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.interceptors.request.use(
  (request) => {
    // console.log(request);
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // console.log(response);
    // Edit response config
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const App = (props) => {
  const location = useLocation();
  const [userAccess, setUserAccess] = useState({
    username: "",
    password: "",
    userId: "",
    functionCode: "",
    show: false,
    isQueuing: false,
  });

  const [userDetails, setUserDetails] = useState({});
  const [appDetails, setAppDetails] = useState({
    isQueuing: false,
  });
  const [isQueuing, setIsQueuing] = useState(false);

  const navigate = useNavigate();
  // const stateDetails = useSelector((state) => state.stateDetails);
  // const dispatch = useDispatch();

  const handleProfile = () => {
    navigate("/userprofile/" + userDetails.UserId);
  };
  const handleChangeUserPassword = () => {
    navigate("/changepassword/" + userDetails.UserId);
  };
  const handleLogOut = () => {
    confirm({
      title: "Logout system now?",
      icon: <UserOutlined />,
      content: "Confirm Logout",
      onOk() {
        Cookies.remove("AlertsShown", { path: "/" });
        Cookies.remove("TokenId", { path: "/" });
        Cookies.remove("Username", { path: "/" });
        Cookies.remove("Fullname", { path: "/" });
        Cookies.remove("UserId", { path: "/" });
        Cookies.remove("ApiUrl", { path: "/" });
        Cookies.remove("ProfilePic", { path: "/" });
        Cookies.remove("BranchCode", { path: "/" });
        // dispatch(updateState({}));
        setUserDetails({});
        navigate("/login");
      },
      onCancel() {},
    });
  };

  const handleUsers = async () => {
    await WithAccess(userDetails.UserId, 1000).then((res) => {
      if (res === true) {
        navigate("/users");
      } else {
        toast.error("Access denied for module-Users", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  const handleQueuing = () => {
    // window.open(Cookies.get("Url") + "/q", "_blank");

    navigate("/queuing");
  };
  const handleNavigate = async (path, moduleId, moduleName) => {
    await WithAccess(userDetails.UserId, moduleId).then((res) => {
      if (res === true) {
        navigate(path);
      } else {
        toast.error("Access denied for module-" + moduleName, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleBranches = async () => {
    await WithAccess(userDetails.UserId, 1004).then((res) => {
      if (res === true) {
        navigate("/branches");
      } else {
        toast.error("Access denied for module-Branches", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleCashierReports = () => {
    navigate("/cashierreports");
  };

  const handleCommonReports = () => {
    navigate("/commonreports");
  };
  const handleListOfItems = async () => {
    await WithAccess(userDetails.UserId, 2056).then((res) => {
      if (res === true) {
        navigate("/listofitems");
      } else {
        toast.error("Access denied for module-Items", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handlePackageCharges = async () => {
    await WithAccess(userDetails.UserId, 2060).then((res) => {
      if (res === true) {
        navigate("/listofpackagecharges");
      } else {
        toast.error("Access denied for module-Package Charges", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleChartOfAccounts = async () => {
    await WithAccess(userDetails.UserId, 1013).then((res) => {
      if (res === true) {
        navigate("/chartofaccounts");
      } else {
        toast.error("Access denied for module-Chart of Accounts", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleDiagResultsLaboratory = async () => {
    await WithAccess(userDetails.UserId, 2065).then((res) => {
      if (res === true) {
        navigate("/laboratoryresults");
      } else {
        toast.error("Access denied for module-Diagnostic Results-Laboratory", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleSuppliers = async () => {
    navigate("/suppliers");
  };

  const handleAccess = async (path, moduleId, description) => {
    await WithAccess(userDetails.UserId, moduleId).then((res) => {
      if (res === true) {
        navigate(path);
      } else {
        toast.error("Access denied for module-" + description, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  useEffect(() => {
    if (Cookies.get("TokenId") !== undefined) {
      if (!userDetails.TokenId) {
        const x = {
          TokenId: Cookies.get("TokenId"),
          Username: Cookies.get("Username"),
          Fullname: Cookies.get("Fullname"),
          UserId: Cookies.get("UserId"),
          ProfilePic:
            Cookies.get("ApiUrl") +
            "/GetProfilePicture?UserId=" +
            Cookies.get("UserId") +
            "&date=" +
            Date.now(),
        };
        // dispatch(updateState(x));
        setUserDetails(x);
      }
    }
  }, []);

  useState(() => {}, [window.location.href]);

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="mnuProfile" onClick={handleProfile}>
  //       <Icon component={BiUserPin} /> Profile
  //     </Menu.Item>
  //     <Menu.Item
  //       key="mnuChangePassword"
  //       onClick={() => handleChangeUserPassword()}
  //     >
  //       <Icon component={BiKey} /> Change Password
  //     </Menu.Item>
  //     <Menu.Item key="mnuLogOut" onClick={handleLogOut}>
  //       <Icon component={BiLogOut} />
  //       Logout
  //     </Menu.Item>
  //   </Menu>
  // );

  const items = [
    getItem(
      `${userDetails.Username}-${Cookies.get("BranchCode")}`,
      "subUser",
      <UserOutlined />,
      [
        getItem("Profile", "subProfile"),
        getItem("Change Password", "subChangePassword"),
        getItem("Logout", "subLogOut"),
      ]
    ),
    getItem("File", "sub2", <FileTextOutlined />, [
      getItem("Branches", "subBranches"),
      getItem("Chart Of Accounts", "subChartOfAccounts"),
      getItem("Doctors", "subSonologists"),
      getItem("Hmo/Company/Guarantors", "subHmo"),
      getItem("Items", "subItems"),
      getItem("Laboratory Templates", "subLabTemplates"),
      getItem("Medical Technologists", "subMedTechs"),
      getItem("Package Charges", "subPackageCharges"),
      getItem("Suppliers", "subSuppliers"),
      getItem("Users", "subUsers"),
      getItem("X-Ray Result Templates", "subXrayResultTemplates"),
      getItem("Ultrasound Result Templates", "subUltrasoundResultTemplates"),
    ]),
    getItem("Transaction", "subTransaction", <FileDoneOutlined />, [
      getItem("Cashier", "subTransactionCashier"),
      getItem("Charge Slips", "subTransactionChargeSlips"),
      getItem("Patients", "subTransactionPatients"),
      getItem("Stock Request", "subTransactionStockRequest"),
    ]),
    getItem(
      "Diagnostic Results",
      "subDiagnosticResults",
      <MedicineBoxOutlined />,
      [
        getItem("Ecg", "subDiagResultsEcg"),
        getItem("Laboratory", "subDiagResultsLaboratory"),
        getItem("Medical Examinations", "subPhysicalExaminations"),
        getItem("Ultrasound", "subDiagResultsUltrasound"),
        getItem("X-Ray", "subDiagResultsXray"),
      ]
    ),
    getItem(
      "Materials Management",
      "subMaterialsManagement",
      <AuditOutlined />,
      [
        getItem("Purchase Orders", "subPo"),
        getItem("Quantity On Hand", "subQuantityOnHand"),
        getItem("Receiving", "subReceiving"),
        getItem("Render Stock Requests", "subRenderStockRequests"),
      ]
    ),
    getItem("Financials", "subFinancials", <BarChartOutlined />, [
      getItem("Accounts Payable", "subFinAccountsPayable"),
      getItem("Chargeslip Posting", "subFinChargeSlipPosting"),
      getItem("Check Vouchers", "subFinCheckVouchers"),
      getItem("General Journals", "subFinGeneralJournals"),
      getItem("Petty Cash", "subFinPettyCashTransactions"),
      // getItem("Fixed Assets", "subFinFixedAssets"),
    ]),
    getItem("Reports", "subReports", <AreaChartOutlined />, [
      getItem("Administrator", "subReportsAdmin"),
      getItem("Cashier", "subReportsCashier"),
      getItem("Common", "subReportsCommon"),
      getItem("Financials", "subReportsFinancials"),
      getItem("Hmo", "subHmoReports"),
      getItem("Materials Management", "subReportsMaterialsManagement"),
    ]),
    getItem("Tools", "subTools", <ToolOutlined />, [
      getItem("ICD10 Explorer", "subToolsIcd10Explorer"),
      getItem("Patient Info. Merger", "subToolsInfoMerger"),
      getItem("Transaction Log", "subToolsTransactionLog"),
      getItem("Queuing", "subToolsQueuing"),
    ]),
  ];
  const handleMenuClick = (e) => {
    if (e.key === "subBranches") {
      handleBranches();
    } else if (e.key === "subChartOfAccounts") {
      handleChartOfAccounts();
    } else if (e.key === "subSonologists") {
      handleAccess("/doctors", 2067, "Doctors");
    } else if (e.key === "subHmo") {
      handleAccess("/hmocompanyguarantors", 2103, "Hmo/Company/Guarantors");
    } else if (e.key === "subItems") {
      handleListOfItems();
    } else if (e.key === "subLabTemplates") {
      handleAccess("/laboratorytemplates", 2106, "Laboratory Templates");
    } else if (e.key === "subMedTechs") {
      handleAccess("/medicaltechnologists", 2100, "Medical Technologists");
    } else if (e.key === "subPackageCharges") {
      handlePackageCharges();
    } else if (e.key === "subUsers") {
      handleUsers();
    } else if (e.key === "subXrayResultTemplates") {
      handleAccess("/xrayresulttemplates", 2070, "X-Ray Result Templates");
    } else if (e.key === "subUltrasoundResultTemplates") {
      handleAccess(
        "/ultrasoundresulttemplates",
        2079,
        "Ultrasound Result Templates"
      );
    } else if (e.key === "subTransactionCashier") {
      navigate("/cashier");
    } else if (e.key === "subTransactionPatients") {
      navigate("/patients");
    } else if (e.key === "subTransactionStockRequest") {
      navigate("/stockrequests");
    } else if (e.key === "subDiagResultsLaboratory") {
      handleDiagResultsLaboratory();
    } else if (e.key === "subDiagResultsXray") {
      handleAccess("/xrayresults", 2073, "X-Ray Result");
    } else if (e.key === "subDiagResultsUltrasound") {
      handleAccess("/ultrasoundresults", 2076, "Ultrasound Result");
    } else if (e.key === "subDiagResultsEcg") {
      handleAccess("/ecg", 2117, "ECG Result");
    } else if (e.key === "subPhysicalExaminations") {
      handleAccess("/medicalexaminations", 2089, "Physical Examination");
    } else if (e.key === "subPo") {
      handleAccess("/purchaseorders", 2082, "Purchase Orders");
    } else if (e.key === "subQuantityOnHand") {
      navigate("/quantityonhand");
    } else if (e.key === "subReceiving") {
      handleAccess("/receiving", 2086, "Stock Receiving-List");
    } else if (e.key === "subRenderStockRequests") {
      navigate("/renderstockrequests");
    } else if (e.key === "subFinAccountsPayable") {
      navigate("/accountspayable");
    } else if (e.key === "subFinChargeSlipPosting") {
      navigate("/chargeslipposting");
    } else if (e.key === "subFinCheckVouchers") {
      navigate("/checkvouchers");
    } else if (e.key === "subFinGeneralJournals") {
      navigate("/generaljournals");
    } else if (e.key === "subFinPettyCashTransactions") {
      navigate("/pettycashtransactions");
    } else if (e.key === "subFinFixedAssets") {
      navigate("/fixedassets");
    } else if (e.key === "subReportsCashier") {
      handleCashierReports();
    } else if (e.key === "subReportsCommon") {
      handleCommonReports();
    } else if (e.key === "subReportsFinancials") {
      navigate("/financialreports");
    } else if (e.key === "subHmoReports") {
      handleAccess("/hmoreports", 2094, "Hmo Reports");
    } else if (e.key === "subReportsMaterialsManagement") {
      handleAccess(
        "/materialsmanagementreports",
        2085,
        "Reports-Materials Management"
      );
    } else if (e.key === "subToolsIcd10Explorer") {
      navigate("/icd10explorer");
    } else if (e.key === "subToolsInfoMerger") {
      navigate("/infomerger");
    } else if (e.key === "subToolsTransactionLog") {
      navigate("/transactionlog");
    } else if (e.key === "subTransactionChargeSlips") {
      handleNavigate("/chargeslips", 1048, "ChargeSlips");
    } else if (e.key === "subProfile") {
      handleProfile();
    } else if (e.key === "subChangePassword") {
      handleChangeUserPassword();
    } else if (e.key === "subLogOut") {
      handleLogOut();
    } else if (e.key === "subToolsQueuing") {
      handleQueuing();
    } else if (e.key === "subSuppliers") {
      handleSuppliers();
    } else if (e.key === "subReportsAdmin") {
      navigate("/adminreports");
    }
    //queuing
  };
  useEffect(() => {
    if (location.pathname === "/queuing") {
      setIsQueuing(true);
    } else {
      setIsQueuing(false);
    }
  }, [location]);
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Layout hasSider>
          {!isNullOrUndefinedOrBlank(userDetails.Username) &&
          isQueuing === false ? (
            <Sider
              width={250}
              style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <div className="logo">
                <img
                  src="/webcimslogo.png"
                  alt="logo"
                  onClick={() => navigate("/")}
                  style={{ height: 50, width: 250 }}
                />
              </div>

              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["subFile"]}
                items={items}
                onClick={handleMenuClick}
              />
            </Sider>
          ) : null}
          <Layout
            className="site-layout "
            style={{
              marginLeft:
                userDetails.Username !== undefined && isQueuing === false
                  ? 250
                  : 0,
            }}

            // style={{
            //   marginLeft: `${
            //     !isNullOrUndefinedOrBlank(userDetails.Username) ? 200 : 200
            //   }`,
            // }}
          >
            <Content
              style={{
                margin: "8px 8px 0",
                overflow: "initial",
              }}
            >
              <div className="site-layout-background">
                <AppContext.Provider
                  value={{
                    userAccess,
                    setUserAccess,
                    userDetails,
                    setUserDetails,
                    appDetails,
                    setAppDetails,
                  }}
                >
                  <Routesx />
                </AppContext.Provider>
              </div>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              <div className="flex flex-row justify-center">
                <div className="mr-2">webCims Powered By</div>

                <a
                  href="https://comlogikph.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="mx-auto"
                    src="/logo.png"
                    alt="Comlogik Logo"
                    width="128"
                    height="16"
                  />
                </a>
              </div>
            </Footer>
          </Layout>
        </Layout>
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          autoClose={3000}
          closeOnClick
          progress={undefined}
          theme="colored"
        />
      </QueryClientProvider>
    </div>
  );
};

export default App;
