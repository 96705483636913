/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "App";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";
import "../login.css";
// import { updateState } from "../actions";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formItemLayout } from "helpers";
import { Input, Form, Button, Select } from "antd";
import { ExportOutlined } from "@ant-design/icons";
const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_URL
    : process.env.REACT_APP_PUBLIC_URL;
const Login = () => {
  const { setUserDetails } = useContext(AppContext);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const [branches, setBranches] = useState([]);
  const handleSubmit = async (values) => {
    setIsSaving(true);
    try {
      await axios
        .post(apiUrl + "Login", values)
        .then((res) => {
          setIsSaving(false);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + Cookies.get("TokenId");
          Cookies.set("TokenId", res.data.tokenId, {
            expires: 1,
            path: "/",
          });
          Cookies.set("Username", values.Username, {
            expires: 1,
            path: "/",
          });

          Cookies.set("BranchCode", values.BranchCode, {
            expires: 1,
            path: "/",
          });

          Cookies.set("UserId", res.data.userId, { expires: 1, path: "/" });
          Cookies.set("Fullname", res.data.parameter.Fullname, {
            expires: 1,
            path: "/",
          });

          Cookies.set("ApiUrl", apiUrl, {
            expires: 1,
            path: "/",
          });
          Cookies.set("Url", url, {
            expires: 1,
            path: "/",
          });
          Cookies.set(
            "ProfilePic",
            apiUrl +
              "/GetProfilePicture?UserId=" +
              res.data.userId +
              "&date=" +
              Date.now(),
            {
              expires: 1,
              path: "/",
            }
          );

          const x = {
            TokenId: res.data.tokenId,
            Username: values.Username,
            Fullname: res.data.parameter.Fullname,
            UserId: res.data.userId,
            ProfilePic:
              apiUrl +
              "/GetProfilePicture?UserId=" +
              res.data.userId +
              "&date=" +
              Date.now(),
          };
          // dispatch(updateState(x));

          axios.defaults.baseURL = apiUrl;
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.tokenId;

          setUserDetails(x);
          navigate("/");
        })
        .catch((err) => {
          let mess = "";
          console.log(mess);
          if (err.response.data) {
            mess = err.response.data.Message;
          } else if (err.request) {
            mess = err.message;
          } else {
            mess = err.message;
          }
          toast.error(mess, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      setIsSaving(false);
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsSaving(false);
    }
  };
  useEffect(() => {
    if (Cookies.get("TokenId") !== undefined) {
      navigate("/");
    }

    const handleGetBranches = async () => {
      // axios.defaults.headers.common["Authorization"] = "Bearer " + tokenId;
      await axios
        .get(apiUrl + "GetListOfRecordsx", {
          params: {
            sp: "udp_GetListOfBranches",
            parameters: {},
          },
        })
        .then((res) => {
          setBranches(res.data);
        })
        .catch((err) => {
          toast.error(err.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    };
    handleGetBranches();
  }, []);
  return (
    <div className="containerLogIn">
      <div className="cardBackground w-screen max-w-screen-sm mx-auto mt-10">
        <h1 className="text-center">Login</h1>
        <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Branch"
            name="BranchCode"
            rules={[
              {
                required: true,
                message: "Please select a branch!",
              },
            ]}
          >
            <Select showSearch>
              {branches.map((d) => (
                <Select.Option key={d.Code}>{d.Name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Username"
            name="Username"
            rules={[
              {
                required: true,
                message: "Username is required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="Password"
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div className="flex justify-end">
            <Form.Item>
              <Button
                type="primary"
                icon={<ExportOutlined />}
                //  disabled={isSaving}
                loading={isSaving}
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item>
              <Button
                type="link"
                //  disabled={isSaving}
                loading={isSaving}
                onClick={() => navigate("/forgotpassword")}
              >
                Forgot password
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
