import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./styles/css/antd.css";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { registerLicense } from "@syncfusion/ej2-base";
import Cookies from "js-cookie";
//theme
import "primereact/resources/themes/fluent-light/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFaclZJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjX31dcHVXRmdcV0w="
);
if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

Cookies.set("AlertsShown", 0);
ReactDOM.render(
  <Router>
    <App />
  </Router>,

  document.getElementById("root")
);
