/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useContext } from "react";
import { AppContext } from "App";
import { Button } from "antd";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  Print,
  TextSelection,
  TextSearch,
  FormFields,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";

const UltrasoundResultOthers = () => {
  // pdfviewer.serviceUrl = Cookies.get("ApiUrl");
  // pdfviewer.appendTo("#PdfViewer");
  // pdfviewer.load("afb.pdf", null);
  const { setAppDetails, appDetails } = useContext(AppContext);
  const viewer = useRef(null);
  const handleGetVersion = async () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + Cookies.get("TokenId");

    await axios
      .get(Cookies.get("ApiUrl") + "GetListOfRecords", {
        params: {
          sp: "udp_GetVersion",
          parameters: {},
        },
      })
      .then((res) => {
        const version = Cookies.get("version");
        if (res.data[0].value !== version) {
          Cookies.set("version", res.data[0].value);
          window.location.reload(true);
        }
      });
  };
  // const handleDownloadResult = async () => {
  //   await axios({
  //     url: "https://ldcapi.anywheremed.ph/api/labresult", //your url
  //     method: "POST",
  //     responseType: "blob", // important
  //     data: {
  //       reportname: "resultchem.rpt",
  //       webKey: "9gxrAhHyDejGPFYNZmCwt",
  //       resultid: "fa1490b6-56a5-4ad5-abc7-aea500d97cc7",
  //       branchcode: "CALA",
  //     },
  //   }).then((response) => {
  //     let newBlob;
  //     newBlob = new Blob([response.data], {
  //       type: "application/pdf",
  //     });
  //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //       window.navigator.msSaveOrOpenBlob(newBlob);
  //       return;
  //     }
  //     const data = window.URL.createObjectURL(newBlob);
  //     const a = document.createElement("a");
  //     a.setAttribute("href", data);
  //     a.setAttribute("target", "_blank");

  //     a.click();
  //   });
  // };
  const handleFillFields = () => {
    var viewer = document.getElementById("container").ej2_instances[0];
    var formFields = viewer.retrieveFormFields();
    formFields.forEach((element, index) => {
      if (element.name === "PatientName") {
        formFields[index].value = "Allan D. Fabular";
        viewer.updateFormFieldsValue(formFields[index]);
      }
    });
  };
  const handleDownload = () => {
    var pdfViewer = document.getElementById("container").ej2_instances[0];
    // pdfViewer.serverActionSettings.download = "PrintImages";
    // pdfViewer.print.print();
    // pdfViewer.serverActionSettings.download = "SaveDocument";
    // pdfViewer.serverActionSettings.download = "SaveDocument";
    pdfViewer.download();
  };
  useEffect(() => {
    handleGetVersion();
    setAppDetails({ ...appDetails, isQueuing: false });
    // handleFillFields();
  }, []);
  return (
    <div>
      <h1>Home</h1>
      <Button onClick={() => handleDownload()}>Download</Button>
      <Button onClick={() => handleFillFields()}>FillFields</Button>
      <PdfViewerComponent
        id="container"
        documentPath="2.pdf"
        serviceUrl={Cookies.get("ApiUrl") + "pdfviewer/"}
        style={{ height: "640px" }}
        enablePrint={true}
        enableDownload={true}
        enableInkAnnotation={false}
        enableToolbar={false}
        ref={viewer}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            Print,
            TextSelection,
            TextSearch,
            FormFields,
          ]}
        />
      </PdfViewerComponent>

      {/* <iframe
        src="http://localhost:52219?ReportName=report5"
        title="Report"
        style={{
          width: "100%",
          minHeight: "100vh",
        }}
      ></iframe> */}
    </div>
  );
};

export default UltrasoundResultOthers;
