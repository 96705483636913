import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";

import LogIn from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import ManualLaboratoryResult from "pages/Laboratory/ManualLaboratoryResult";
import UltrasoundResultOthers from "pages/Ultrasound/UltrasoundResultOthers";
import DocumentEditor from "pages/PreEmployment/DocumentEditor";
const ApeForm = lazy(() => import("pages/PreEmployment/ApeForm"));
const ApeForms = lazy(() => import("pages/PreEmployment/ApeForms"));
const Queuing = lazy(() => import("pages/Tools/Queuing"));
const UserBranches = lazy(() => import("pages/UserBranches"));
const UserAccess = lazy(() => import("pages/UserAccess"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));
const Home = lazy(() => import("pages/Home"));
const Users = lazy(() => import("pages/Users"));
const UserProfile = lazy(() => import("pages/UserProfile"));
const Branches = lazy(() => import("pages/Branches"));
const BranchProfile = lazy(() => import("pages/BranchProfile"));
const Patients = lazy(() => import("pages/Patients/Patients"));
const PatientProfile = lazy(() => import("pages/Patients/PatientProfile"));
const RegisterPatient = lazy(() =>
  import("pages/Registration/RegisterPatient")
);
const RegisteredPatients = lazy(() =>
  import("pages/Registration/RegisteredPatients")
);

const ChargeSlips = lazy(() => import("pages/ChargeSlips"));
const Cashier = lazy(() => import("pages/Cashier/Transactions"));
const AcceptPayment = lazy(() => import("pages/Cashier/AcceptPayment"));
const Reports = lazy(() => import("pages/Reports"));
const ChargeSlip = lazy(() => import("pages/ChargeSlip"));
const CancelChargeSlip = lazy(() => import("pages/CancelChargeSlip"));
const UserDepartments = lazy(() => import("pages/UserDepartments"));

const CommonReports = lazy(() => import("pages/Reports/Common"));
const AdminReports = lazy(() => import("pages/Reports/Admin"));
const FinancialReports = lazy(() => import("pages/Reports/FinancialReports"));
const CashierReports = lazy(() => import("pages/Reports/CashierReports"));
const MaterialsManagementReports = lazy(() =>
  import("pages/Reports/MaterialsManagementReports")
);
const HmoReports = lazy(() => import("pages/Reports/HmoReports"));
const CancelOr = lazy(() => import("pages/Cashier/CancelOr"));
const Refund = lazy(() => import("pages/Cashier/Refund"));
const ListOfItems = lazy(() => import("pages/Items/ListOfItems"));
const ItemProfile = lazy(() => import("pages/Items/ItemProfile"));
const ItemPrices = lazy(() => import("pages/Items/Prices"));

const ListOfPackageCharges = lazy(() =>
  import("pages/PackageCharges/ListOfPackageCharges")
);
const PackageProfile = lazy(() =>
  import("pages/PackageCharges/PackageProfile")
);
const PackageItems = lazy(() => import("pages/PackageCharges/PackageItems"));

const ChartOfAccounts = lazy(() => import("pages/Financials/ChartOfAccounts"));
const ChartOfAccount = lazy(() => import("pages/Financials/ChartOfAccount"));
const LaboratoryResults = lazy(() =>
  import("pages/Laboratory/LaboratoryResults")
);
const LaboratoryResult = lazy(() =>
  import("pages/Laboratory/LaboratoryResult")
);

const Doctors = lazy(() => import("pages/File/Doctors"));
const DoctorProfile = lazy(() => import("pages/File/DoctorProfile"));

const XrayResultTemplates = lazy(() =>
  import("pages/File/XrayResultTemplates")
);

const XrayResultTemplate = lazy(() => import("pages/File/XrayResultTemplate"));

const XrayResults = lazy(() => import("pages/Xray/XrayResults"));
const XrayResult = lazy(() => import("pages/Xray/XrayResult"));

const UltrasoundResults = lazy(() =>
  import("pages/Ultrasound/UltrasoundResults")
);
const UltrasoundResult = lazy(() =>
  import("pages/Ultrasound/UltrasoundResult")
);
const UltrasoundResultPdf = lazy(() =>
  import("pages/Ultrasound/UltrasoundResultPdf")
);
const UltrasoundResultTemplates = lazy(() =>
  import("pages/File/UltrasoundResultTemplates")
);

const UltrasoundResultTemplate = lazy(() =>
  import("pages/File/UltrasoundResultTemplate")
);

const PuchaseOrders = lazy(() =>
  import("pages/MaterialsManagement/PurchaseOrders")
);
const EcgResults = lazy(() => import("pages/Ecg/EcgResults"));

const PurchaseOrder = lazy(() =>
  import("pages/MaterialsManagement/PurchaseOrder")
);

const Receiving = lazy(() => import("pages/MaterialsManagement/Receiving"));

const StockReceiving = lazy(() =>
  import("pages/MaterialsManagement/StockReceiving")
);

const MedicalExaminations = lazy(() =>
  import("pages/PreEmployment/MedicalExaminations")
);

const MedicalExamination = lazy(() =>
  import("pages/PreEmployment/MedicalExamination")
);
const MedicalTechnologists = lazy(() =>
  import("pages/File/MedicalTechnologists")
);
const HmoCompanyGuarantors = lazy(() =>
  import("pages/File/HmoCompanyGuarantors")
);
const HmoCompanyGuarantorProfile = lazy(() =>
  import("pages/File/HmoCompanyGuarantorProfile")
);
const MedTechProfile = lazy(() => import("pages/File/MedTechProfile"));
const PettyCash = lazy(() => import("pages/Financials/PettyCash"));
const HmoDeduction = lazy(() => import("pages/Hmo/HmoDeduction"));

const LaboratoryTemplates = lazy(() =>
  import("pages/File/LaboratoryTemplates")
);

const LaboratoryTemplate = lazy(() =>
  import("pages/File/LaboratoryTemplateDetails")
);

const SetLaboratoryTemplates = lazy(() =>
  import("pages/Items/SetLaboratoryTemplates")
);

const LaboratoryTemplateDetails = lazy(() =>
  import("pages/File/LaboratoryTemplateDetails")
);

const OrPosting = lazy(() => import("pages/Financials/OrPosting"));
const PettyCashTransactions = lazy(() =>
  import("pages/Financials/PettyCashTransactions")
);
const ArGuarantorPosting = lazy(() =>
  import("pages/Financials/ArGuarantorPosting")
);
const ChargeSlipPosting = lazy(() =>
  import("pages/Financials/ChargeSlipPosting")
);

const AccountsPayable = lazy(() => import("pages/Financials/AccountsPayable"));
const Ap = lazy(() => import("pages/Financials/Ap"));
const CheckVouchers = lazy(() => import("pages/Financials/CheckVouchers"));
const CheckVoucher = lazy(() => import("pages/Financials/CheckVoucher"));
const GeneralJournals = lazy(() => import("pages/Financials/GeneralJournals"));
const GeneralJournal = lazy(() => import("pages/Financials/GeneralJournal"));
const SetInventoryItems = lazy(() => import("pages/Items/SetInventoryItems"));
const StockRequests = lazy(() => import("pages/Transaction/StockRequests"));
const StockRequest = lazy(() => import("pages/Transaction/StockRequest"));
const RenderStockRequests = lazy(() =>
  import("pages/MaterialsManagement/RenderStockRequests")
);
const QuantityOnHand = lazy(() =>
  import("pages/MaterialsManagement/QuantityOnHand")
);
const Icd10Explorer = lazy(() => import("pages/Tools/Icd10Explorer"));
const TransactionLog = lazy(() => import("pages/Tools/TransactionLog"));
const InfoMerger = lazy(() => import("pages/Tools/InfoMerger"));
const ReorderLevel = lazy(() => import("pages/Items/ReorderLevel"));
const AdminDashboard = lazy(() => import("pages/Dashboards/AdminDashboard"));
const FixedAssets = lazy(() => import("pages/FixedAssets/FixedAssets"));
const XrayResult2 = lazy(() => import("pages/Xray/XrayResult2"));
const Suppliers = lazy(() => import("pages/File/Suppliers"));
const SupplierProfile = lazy(() => import("pages/File/SupplierProfile"));
const Routesx = () => {
  const checkAuth = () => {
    const token = Cookies.get("TokenId", "/");

    let isLoggedIn = false;
    if (token === undefined) {
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
    }

    return isLoggedIn;
  };

  return (
    <Routes>
      <Route path="/login" element={<LogIn />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      {/* <Suspense
        fallback={
          <div className="text-center font-bold text-md">Loading page...</div>
        }
      > */}
      <Route
        path="/"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Home />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/users"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Users />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route path="/userprofile">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UserProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UserProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/changepassword/:UserId"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ChangePassword />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/userbranches/:id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <UserBranches />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/useraccess/:id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <UserAccess />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/branches"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Branches />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/branchprofile/:code"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <BranchProfile />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/patients"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Patients />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/patientprofile">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PatientProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PatientProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route path="/registerpatient">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <RegisterPatient />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <RegisterPatient />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/registeredpatients"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <RegisteredPatients />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/chargeslips"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ChargeSlips />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/cashier"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Cashier />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/acceptpayment">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <AcceptPayment />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <AcceptPayment />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="/reports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Reports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="chargeslip/:PatientId"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ChargeSlip />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/commonreports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <CommonReports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/financialreports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <FinancialReports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/hmoreports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <HmoReports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/cashierreports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <CashierReports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/adminreports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <AdminReports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/cancelor/:PaymentId"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <CancelOr />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/refund/:PaymentId"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Refund />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/cancelchargeslip/:ChargeId"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <CancelChargeSlip />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/userdepartments/:id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <UserDepartments />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/listofitems"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ListOfItems />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/itemprofile">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ItemProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ItemProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/prices/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ItemPrices />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/listofpackagecharges"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ListOfPackageCharges />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route path="/packageprofile">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PackageProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PackageProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="/packageitems/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <PackageItems />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/chartofaccounts"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ChartOfAccounts />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/laboratoryresults"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <LaboratoryResults />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/laboratoryresult">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <LaboratoryResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <LaboratoryResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/doctors"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Doctors />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/doctorprofile">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <DoctorProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <DoctorProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/xrayresulttemplates"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <XrayResultTemplates />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/xrayresulttemplate">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <XrayResultTemplate />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <XrayResultTemplate />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/xrayresults"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <XrayResults />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route path="/xrayresult">
        <Route
          path=":ResultId"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <XrayResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <XrayResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route path="/xrayresult2">
        <Route
          path=":ResultId"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <XrayResult2 />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <XrayResult2 />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/ultrasoundresults"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <UltrasoundResults />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route path="/ultrasoundresult">
        <Route
          path=":ResultId"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UltrasoundResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UltrasoundResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route path="/ultrasoundresultpdf">
        <Route
          path=":ResultId"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UltrasoundResultPdf />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UltrasoundResultPdf />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="/ultrasoundresulttemplates"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <UltrasoundResultTemplates />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/ultrasoundresulttemplate">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UltrasoundResultTemplate />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <UltrasoundResultTemplate />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/purchaseorders"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <PuchaseOrders />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/purchaseorder">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PurchaseOrder />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PurchaseOrder />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/materialsmanagementreports"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <MaterialsManagementReports />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/receiving"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Receiving />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/stockreceiving">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <StockReceiving />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <StockReceiving />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/medicalexaminations"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <MedicalExaminations />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/medicalexamination">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <MedicalExamination />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <MedicalExamination />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="hmodeduction/:ChargeId"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <HmoDeduction />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/chartofaccount">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ChartOfAccount />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ChartOfAccount />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route path="/pettycash">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PettyCash />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <PettyCash />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/medicaltechnologists"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <MedicalTechnologists />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/medtechprofile">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <MedTechProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <MedTechProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/hmocompanyguarantors"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <HmoCompanyGuarantors />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/hmocompanyguarantorprofile">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <HmoCompanyGuarantorProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <HmoCompanyGuarantorProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/laboratorytemplates"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <LaboratoryTemplates />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="laboratorytemplate/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <LaboratoryTemplate />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="setlaboratorytemplates/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <SetLaboratoryTemplates />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="laboratorytemplatedetails/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <LaboratoryTemplateDetails />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/manuallaboratoryresult">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ManualLaboratoryResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ManualLaboratoryResult />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/queuing"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Queuing />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/ecg"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <EcgResults />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/orposting"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <OrPosting />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/pettycashtransactions"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <PettyCashTransactions />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/arguarantorposting"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ArGuarantorPosting />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/chargeslipposting"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ChargeSlipPosting />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/accountspayable"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <AccountsPayable />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/ap">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <Ap />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <Ap />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>

      <Route
        path="/checkvouchers"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <CheckVouchers />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route path="/checkvoucher">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <CheckVoucher />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <CheckVoucher />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="/generaljournals"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <GeneralJournals />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route path="/generaljournal">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <GeneralJournal />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <GeneralJournal />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="setinventoryitems/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <SetInventoryItems />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/stockrequests"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <StockRequests />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/stockrequest">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <StockRequest />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <StockRequest />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="/renderstockrequests"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <RenderStockRequests />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/quantityonhand"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <QuantityOnHand />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/icd10explorer"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Icd10Explorer />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/transactionlog"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <TransactionLog />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/infomerger"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <InfoMerger />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/reorderlevel/:Id"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ReorderLevel />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/admindashboard"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <AdminDashboard />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/fixedassets"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <FixedAssets />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/ultrasoundresultothers"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <UltrasoundResultOthers />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/apeforms"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <ApeForms />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/apeform">
        <Route
          path=":id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ApeForm />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <ApeForm />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
      <Route
        path="/documenteditor"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <DocumentEditor />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route
        path="/suppliers"
        element={
          checkAuth() ? (
            <Suspense
              fallback={
                <div className="text-center font-bold text-md">
                  Loading page...
                </div>
              }
            >
              <Suppliers />
            </Suspense>
          ) : (
            <LogIn />
          )
        }
      />

      <Route path="/supplierprofile">
        <Route
          path=":Id"
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <SupplierProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
        <Route
          path=""
          element={
            checkAuth() ? (
              <Suspense
                fallback={
                  <div className="text-center font-bold text-md">
                    Loading page...
                  </div>
                }
              >
                <SupplierProfile />
              </Suspense>
            ) : (
              <LogIn />
            )
          }
        />
      </Route>
    </Routes>
  );
};

export default Routesx;
