/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { formItemLayout } from "helpers";
import { Input, Form, Button } from "antd";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = async (values) => {
    setIsSaving(true);
    try {
      await axios
        .post(apiUrl + "ForgotPassword", values)
        .then((res) => {
          toast.error(
            "We have sent your temporary password in the email address you provided!",
            {
              position: "top-center",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          navigate("/login");
        })
        .catch((err) => {
          let mess = "";
          console.log(mess);
          if (err.response.data) {
            mess = err.response.data.Message;
          } else if (err.request) {
            console.log(err.message);
            mess = err.message;
          } else {
            console.log("Error", err.message);
            mess = err.message;
          }
          toast.error(mess, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      setIsSaving(false);
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsSaving(false);
    }
  };
  useEffect(() => {}, []);
  return (
    <div className="cardBackground w-screen max-w-screen-sm mx-auto mt-10">
      <h1 className="text-center">Forgot Password</h1>
      <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Username"
          name="Username"
          rules={[
            {
              required: true,
              message: "Username is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="EmailAddress"
          rules={[
            {
              required: true,
              message: "Email Address is required!",
            },
            {
              type: "email",
              message: "Invalid email address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="flex justify-end">
          <Form.Item>
            <Button type="primary" loading={isSaving} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;
